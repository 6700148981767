
import React from 'react';
import {
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import classNames from 'classnames';
import { ARROW_LEFT, ARROW_RIGHT } from '../../shared/constants';

const HomepageSliderControllers = (props) => {
  const { horizontalPadding } = props;

  return (
    <div
      className='slider-controllers-wrapper'
      style={{ padding: `0 ${horizontalPadding}px` }}
    >
      <DotGroup
        className='slider-indicator-wrapper'
        renderDots={(slide) => {
          const { currentSlide, totalSlides, visibleSlides } = slide;
          const count = Math.round((totalSlides || 0) - (visibleSlides || 0)) + 1;

          return (
            [...Array(count)].map((_, index) => (
              <div
                className={classNames(
                  'slider-indicator',
                  Math.round(currentSlide || 0) == index && 'slider-indicator-active',
                )}
                key={`slider-indicator-${index}`}
              />
            ))
          )
        }}
      />
      <div className='slider-controllers'>
        <ButtonBack
          className='slider-controller-item'
        >
          <img
            src={ARROW_LEFT}
            alt=""
            className='icon'
          />
        </ButtonBack>
        <ButtonNext
          className='slider-controller-item'
        >
          <img
            src={ARROW_RIGHT}
            alt=""
            className='icon'
          />
        </ButtonNext>
      </div>
    </div>
  );
}

export default HomepageSliderControllers;